.navbar {
  color: #fff;
  padding: 15px;
  text-align: center;
  margin-bottom: 2vh;
  border-bottom: 1px #3c3c3c solid;
}

.logo {
  font-size: 1.8em;
  font-weight: bold;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
