.wordle-input {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  width: 20vw;
}

.wordle-input input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 28px;
  margin-right: 5px;
  caret-color: transparent;
  border: 2px #3c3c3c solid;
  color: #fff;
  font-weight: 700;
  font-family: Helvetica, sans-serif;
}

.wordle-input input:focus {
  /* border: 2px #5c5c5c solid; */
  outline: none
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
}

@keyframes incorrect {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-8px);
  }
  60% {
    transform: translateX(-4px);
  }
}
